<template>
  <div class="legend" style="padding: 20px">
    <el-card shadow="never" class="card-top">
      <el-form ref="form" :inline="true" :model="search">
        <el-form-item label="门店编号：">
          <el-input v-model="search.shopNumber" placeholder="请输入门店编号" />
        </el-form-item>
        <el-form-item label="历史售达方编号：">
          <el-input
            v-model="search.oldLicenseNumber"
            placeholder="请输入历史售达方编号"
          />
        </el-form-item>
        <el-form-item label="现售达方编号：">
          <el-input
            v-model="search.newLicenseNumber"
            placeholder="请输入现售达方编号"
          />
        </el-form-item>
        <el-form-item label="历史实控人名称：">
          <el-input
            v-model="search.oldBossName"
            placeholder="请输入历史实控人名称"
          />
        </el-form-item>
        <el-form-item label="现实控人名称：">
          <el-input
            v-model="search.newBossName"
            placeholder="请输入现实控人名称"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-refresh-left" @click="handleReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <br />
    <el-card shadow="never">
      <el-form ref="form" :inline="true" :model="search">
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleDownload"
            :loading="downloadLoading"
            >门店转让数据导出</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        v-loading="listLoading"
        :data="productList"
        border
        element-loading-text="Loading"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      >
        <el-table-column label="转让流程单号" align="center" prop="orderNumber">
        </el-table-column>
        <el-table-column
          label="历史售达方编号"
          align="center"
          prop="oldLicenseNumber"
        >
        </el-table-column>
        <el-table-column
          label="历史售达方名称"
          align="center"
          prop="oldLicenseName"
        >
        </el-table-column>
        <el-table-column
          label="历史实控人编号"
          align="center"
          prop="oldBossNumber"
        >
        </el-table-column>
        <el-table-column
          label="历史实控人名称"
          align="center"
          prop="oldBossName"
        />
        <el-table-column
          label="现售达方编号"
          align="center"
          prop="newLicenseNumber"
        />
        <el-table-column
          label="现售达方名称"
          align="center"
          prop="newLicenseName"
        />
        <el-table-column
          label="现实控人编号"
          align="center"
          prop="newBossNumber"
        />
        <el-table-column
          label="现实控人名称"
          align="center"
          prop="newBossName"
        />
        <el-table-column
          label="转让时间（取值转让流程归档时间）"
          align="center"
          prop="overTime"
        />
        <el-table-column
          label="转让时门店数据详情"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope" style="text-align: center">
            <el-button
              style="margin-right: 20px"
              type="text"
              @click="handleEdit(scope.row.historicalData)"
              >查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <br /><br />
    <el-row>
      <el-col :span="24" align="center">
        <el-pagination
          background
          :current-page="pageIndex"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize || 10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>

    <el-drawer
      title="查看详情"
      :visible.sync="drawer"
      size="50%;min-width:750px;overflow-y:auto"
    >
      <div class="drawer-box">
        <h2>门店信息</h2>
        <el-form
          ref="form"
          class="drawer-form"
          label-width="120px"
          inline
          label-position="top"
        >
          <el-form-item label="门店名称">
            <el-input
              v-model="formData.shopName"
              :title="formData.shopName"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="省份">
            <el-input
              v-model="formData.province"
              :title="formData.province"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="城市">
            <el-input
              v-model="formData.city"
              :title="formData.city"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="地区">
            <el-input
              v-model="formData.area"
              :title="formData.area"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="街道">
            <el-input
              v-model="formData.street"
              :title="formData.street"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店位置等级">
            <RequestModuleSelect
              v-model="formData.shopPositionLevel"
              label="门店位置等级"
              type="shop_level"
              :showLabel="false"
              readonly
              :title="formData.shopPositionLevel"
            />
          </el-form-item>
          <el-form-item label="门店楼层">
            <el-input
              v-model="formData.shopFloor"
              :title="formData.shopFloor"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店铺号">
            <el-input
              v-model="formData.shopFloorNumber"
              :title="formData.shopFloorNumber"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店经度">
            <el-input
              v-model="formData.longitude"
              :title="formData.longitude"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店纬度">
            <el-input
              v-model="formData.latitude"
              :title="formData.latitude"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店租金">
            <el-input
              v-model="formData.shopRent"
              :title="formData.shopRent"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="开业时间">
            <el-input
              v-model="formData.openingTime"
              :title="formData.openingTime"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="最新装修时间">
            <el-input
              v-model="formData.decorationTime"
              :title="formData.decorationTime"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="经营模式">
            <RequestModuleSelect
              v-model="formData.managementModel"
              :showLabel="false"
              label="经营模式"
              type="management_model"
              readonly
            />
          </el-form-item>
          <el-form-item label="联系人" v-if="formData.managementModel !== '10'">
            <el-input
              v-model="formData.contactPerson"
              :title="formData.contactPerson"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系人身份证"
            v-if="formData.managementModel !== '10'"
          >
            <el-input
              v-model="formData.contactPersonIdcard"
              :title="formData.contactPerson"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系人电话"
            v-if="formData.managementModel !== '10'"
          >
            <el-input
              v-model="formData.contactPersonPhone"
              :title="formData.contactPerson"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="门店状态">
            <el-select
              v-model="formData.shopStatus"
              placeholder="请选择门店状态"
              readonly
            >
              <el-option label="生效" :value="0"> </el-option>
              <el-option label="失效" :value="1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门店状态说明">
            <el-select
              v-model="formData.statusExplain"
              placeholder="请选择状态说明"
              readonly
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in statusExplainOptions"
                :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实际撤店时间">
            <el-input
              v-model="formData.actualWithdrawTime"
              :title="formData.actualWithdrawTime"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店性质：">
            <RequestModuleSelect
              :value="formData.storeNature"
              type="shop_nature_code"
              readonly
            />
          </el-form-item>
          <el-form-item class="formItem" label="渠道类型：">
            <RequestModuleSelect
              :value="formData.channel"
              placeholder=""
              type="cooperation_code"
              readonly
            />
          </el-form-item>
          <el-form-item class="formItem" label="渠道性质：">
            <RequestModuleSelect
              :value="formData.shopType"
              type="store_type"
              readonly
            />
          </el-form-item>
          <el-form-item class="formItem" label="渠道性质二级分类：">
            <el-input
              v-model="formData.shopTypeValue"
              readonly
              :title="formData.shopTypeValue"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店地址：">
            <el-input
              v-model="formData.shopName"
              readonly
              :title="formData.shopName"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item class="formItem" label="门店面积（㎡）：">
            <el-input v-model="formData.shopArea" readonly :title="formData.shopArea"></el-input>
          </el-form-item> -->
          <el-form-item class="formItem" label="独立外立面门头：">
            <RequestModuleSelect
              :value="formData.independentDoorHeadFlag"
              type="independent_door_head_flag_boolean"
              readonly
            />
          </el-form-item>
          <el-form-item class="formItem" label="二级分销商：">
            <el-input
              v-model="formData.contactPerson"
              readonly
              :title="formData.contactPerson"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="二级分销商身份证：">
            <el-input
              v-model="formData.contactPersonIdcard"
              readonly
              :title="formData.contactPersonIdcard"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="二级分销商联系方式：">
            <el-input
              v-model="formData.contactPersonPhone"
              readonly
              :title="formData.contactPersonPhone"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="是否有儿童配套专区：">
            <el-input
              :value="formData.childrenSupportingArea > 0 ? '是' : '否'"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="formData.childrenSupportingArea > 0"
            class="formItem"
            label="儿童配套专区面积："
          >
            <el-input
              v-model="formData.childrenSupportingArea"
              readonly
              :title="formData.childrenSupportingArea"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <h2>品牌经营</h2>
        <el-table
          :data="formData.shopBrandList || []"
          border
          highlight-current-row
          :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        >
          <el-table-column label="经营品牌" align="center" prop="name">
          </el-table-column>
          <el-table-column label="经营系列" align="center" prop="bigBrandName">
          </el-table-column>
          <el-table-column label="品牌门店面积" align="center" prop="brandArea">
          </el-table-column>
          <el-table-column
            label="品牌样品套数"
            align="center"
            prop="sampleSets"
          >
          </el-table-column>
        </el-table>
        <div class="submit-btn">
          <el-button style="width: 150px" type="primary" @click="handleClose"
            >返 回</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { franchiseChangeShopList } from '@/api/businessOos'
import { datadictionaryList } from '@/api/common'
import { shopRent } from '@/api/businessOos'
import data from '@/components/CustomFormItem/data/module.data'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'

import { excelJsonToExcel } from './fn'
import { aStoreDetail } from './json'
export default {
  components: {
    RequestModuleSelect,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItemInput
  },
  data() {
    return {
      search: {},
      listLoading: false,
      productList: [], // 产品列表
      selecttion: [],
      pageIndex: 1,
      pageTotal: 1,
      pageSize: 1,
      loading: false,
      drawer: false,
      isUpdate: false,
      formData: {
        shopBrandList: {}
      },
      form: {},
      showCode: false,
      statusExplainOptions: [
        { label: '待设计', value: '9' },
        { label: '设计中', value: '10' },
        { label: '装修中', value: '20' },
        { label: '装修完成', value: '30' },
        { label: '正常营业', value: '40' },
        { label: '装修失效', value: '50' },
        { label: '撤店失效', value: '60' },
        { label: '人工失效', value: '61' },
        { label: '转让失效', value: '70' }
      ],
      downloadLoading: false
    }
  },
  created() {
    this.getDealerUserList()
  },
  methods: {
    async handleDownload() {
      this.downloadLoading = true
      try {
        const { data } = await franchiseChangeShopList({
          search: this.search,
          currPage: 1,
          pageSize: 1000000
        })

        const aDownloadData = data.map(
          ({
            shopNumber,
            orderNumber,
            oldLicenseNumber,
            oldLicenseName,
            oldBossNumber,
            oldBossName,
            newLicenseNumber,
            newLicenseName,
            newBossNumber,
            newBossName,
            overTime,
            historicalData
          }) => ({
            shopNumber,
            orderNumber,
            oldLicenseNumber,
            oldLicenseName,
            oldBossNumber,
            oldBossName,
            newLicenseNumber,
            newLicenseName,
            newBossNumber,
            newBossName,
            overTime,
            ...JSON.parse(historicalData)
          })
        )
        
        excelJsonToExcel(
          aStoreDetail,
          aDownloadData,
          '门店转让详情列表'
        )
      } catch (error) {
        console.log(error)
        this.$message.error('excel导出失败')
      } finally {
        this.downloadLoading = false
      }
    },
    handleSelectionChange(val) {
      this.selecttion = val.map(item => item.id)
    },
    beforeClose() {
      this.showCode = false
      document.querySelector('#codeImg').innerHTML = ''
    },
    handleCheck(item) {
      this.showCode = true
      this.$nextTick(function() {
        new QRCode('codeImg', {
          width: 180,
          height: 180, // 高度
          text: 'https://derucci.net/d/?code=' + item.code,
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.L,
          QRCodeVersion: 1
        })
      })
    },
    handleDelete(delArr) {
      if (!delArr.length) {
        this.$message.error('至少选择一条删除的数据')
        return
      }
      productManualDelete(delArr).then(res => {
        this.$message.success('删除成功')
        this.getDealerUserList(this.pageIndex, this.pageSize, this.search)
      })
    },
    handleClose() {
      this.form = {}
      this.drawer = false
      this.$refs.form.resetFields()
    },
    onGetEditorContent(val) {
      this.form.details = val
    },
    handleEdit(item) {
      try {
        this.formData = JSON.parse(item)
      } catch {
        this.formData = {}
      }

      this.drawer = true
    },
    handleReset() {
      this.search = {
        shopNumber: '',
        oldLicenseNumber: '',
        newLicenseNumber: ''
      }
      this.getDealerUserList()
    },
    handleSearch() {
      this.getDealerUserList(1, this.pageSize, this.search)
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getDealerUserList(this.pageIndex, pageSize, this.search)
    },
    handleCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getDealerUserList(pageIndex, this.pageSize, this.search)
    },
    /**
     * 获取产品列表
     */
    async getDealerUserList(page = 1, limit = 10, search = {}) {
      this.listLoading = true
      const { code, data, msg, pageSize, totalCount, totalPage } =
        await franchiseChangeShopList({
          ...search,
          currPage: page,
          pageSize: limit
        }).finally(() => (this.listLoading = false))

      if (code === 0) {
        this.productList = data
        this.pageTotal = totalCount
        this.pageSize = pageSize
        this.form.code = totalCount + 1 || 1
      } else {
        this.message(code, msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-btn {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  z-index: 9;
}
.drawer-box {
  padding-bottom: 60px;
  & h2 {
    padding: 20px;
    font-size: 20px;
  }
}

::v-deep {
  #codeImg {
    img {
      margin: 0 auto;
    }
  }

  .drawer-form {
    padding: 0 20px;
    .el-form-item--small.el-form-item {
      width: 230px;
      margin-right: 0;
      margin-left: -1px;
      margin-top: -1px;
      border: 1px solid #dfe4ed;
    }

    .el-form-item__content {
      width: 100%;
      padding: 10px;
    }

    .el-form-item--small .el-form-item__label {
      width: 100%;
      border-bottom: 1px solid #dfe4ed;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #e2f2fb;
    }
  }
}

@media screen {
}
</style>
