<template>
  <div>
    <el-table
      ref="filterTable"
      border
      :data="dataList"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      style="width: 100%">
      <el-table-column prop="achievementCode" label="业绩品牌编码" />
      <el-table-column prop="bigBrandName" label="品牌" />
      <el-table-column prop="name" label="系列" />
      <!-- <el-table-column label="授权城市" prop="authorizedCityName" /> -->
      <el-table-column prop="ifEnabled" label="状态">
        <template slot-scope="scope">
          <span> {{ scope.row.ifEnabled === 0 ? '生效' : '失效' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态说明">
        <template #default="{ row }">
          <div>
            <!-- 门店 -->
            <span>
              {{ shopStatusExplain(row.statusExplain) }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间（下单时间）"
        prop="createTime" />
      <el-table-column
        prop="brandArea"
        label="品牌门店面积" />
      <el-table-column
        prop="sampleSets"
        label="品牌样品套数" />
      <el-table-column
        prop="authorizedTime"
        label="门店系列授权时间"
        width="150" />
      <el-table-column
        prop="overTime"
        label="门店系列取消时间"
        width="150" />
      <el-table-column label="系列变更原因" width="150">
        <template #default="{ row }">
          <div>
            <el-button
              type="text"
              class="handle"
              @click="handleClickUpdate(row.id)">
              系列变更原因详情
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
      title="系列变更原因详情"
      :visible.sync="updateVisiable"
      direction="rtl"
      :size="`${widthStyle};overflow-y:auto`"
      :append-to-body="true">
      <el-table
        ref="filterTable"
        border
        :data="updateList"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        style="width: 100%">
        <el-table-column prop="createTime" label="变更时间" />
        <el-table-column prop="orderNumber" label="流程单号" />
        <el-table-column prop="title" label="流程名称" />
        <el-table-column prop="afterInfo" label="系列变更原因">
          <template #default="{ row }">
            <div>
              {{
                filterTypeText(
                  row.afterInfo
                    ? JSON.parse(row.afterInfo).update_reason
                    : JSON.parse(row.historicalData).updateReason
                )
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import { changeTrainStatus, requestUpdateDetail } from '@/api/businessOos'
import RelevantTable from './relevantTable.vue'
import dataParams from '@/components/CustomFormItem/data/module.data'
import { datadictionaryList } from '@/api/common'
import { getStorage } from '@/utils/storage'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    authorizedCity: {
      type: String,
      default: ''
    },
    rowData: {
      type: Object,
      default: {}
    }
  },
  components: {
    RelevantTable
  },
  watch: {
    dataList: {
      deep: true,
      handler: val => {}
    }
  },
  data() {
    return {
      visiable: false,
      widthStyle: '50%',
      id: null,
      updateVisiable: false,
      updateList: [],
      typeList: []
      // shopStatus: []
    }
  },
  computed: {
    shopStatusExplain() {
      return val => {
        const obj = {
          9: '待设计',
          10: '设计中',
          20: '装修中',
          30: '装修完成',
          40: '正常营业',
          45: '整改中',
          50: '装修失效',
          51: '取消经营',
          60: '撤店失效',
          61: '人工失效',
          62: '系列全失效引起门店失效',
          70: '转让失效'
        }
        return obj[val]
      }
    }
  },
  created() {
    console.log(this.$route)
    this.getTypeList()
    if (document.body.clientWidth < 1200) {
      this.widthStyle = '100%'
    }
    // this.shopStatus = dataParams.shop_status
  },
  methods: {
    filterTypeText(code) {
      if (code) {
        return this.typeList.find(item => item.code === String(code)).content
      }
    },
    // 系列词典
    async getTypeList() {
      const res = await datadictionaryList({
        type: 'shop_change',
        module: 'distributor',
        pageSize: 500
      })
      this.typeList = res.data
    },
    // 系列变更
    async handleClickUpdate(id) {
      const res = await requestUpdateDetail(id)
      // console.log(res)
      this.updateList = res.data
      this.updateVisiable = true
    },
    async handleTrainStatus(status, id, row) {
      const res = await changeTrainStatus({
        id,
        trainStatus: status === 0 ? 1 : 0
      })
      if (res.code === 0) {
        const userInfo = getStorage('userInfo') || {}
        this.$parent.$parent.$parent.getBossCorrelationInfo({
          shopBrandNumber: row.shop_brand_number,
          userId: userInfo.accountId,
          dataType: 'brand'
        })
      }
    },
    handleSort(id) {
      this.id = id
      this.visiable = true
    },
    shopStatus(value) {
      const text = dataParams.shop_status.find(item => item.code === value)
      if (text?.content) {
        return text?.content
      }
      return ''
    },
    businessStatus(value) {
      const text = dataParams.receiver_type.find(item => item.code === value)
      if (text?.content) {
        return text?.content
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
