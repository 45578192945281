<template>
  <el-drawer
    title="门店数据变更"
    :visible.sync="storeVisibleDialog"
    :before-close="cancel"
    direction="rtl"
    size="50%;overflow-y:auto">
    <el-form
      ref="form"
      v-if="storeVisibleDialog"
      label-width="100%"
      :inline="true"
      style="padding: 0 30px 30px"
      class="form">
      <required-form-item-select
        class="formItem"
        v-model="storeForm.bossId"
        label="实控人编号"
        labelKey="bossNumber"
        valueKey="id"
        :selectProps="{ filterable: true, remote: true }"
        :total-count="bossIdCountTotal"
        :getDataList="getbossIdList"
        :getNextList="getbossIdList"
        @changeSelectedItem="
          e => {
            realName = e.realNameidCard = e.idCard
          }
        "
        @onOptionsListNotFound="onNotFoundBossList">
        <template v-slot="{ scope }">
          <span>{{ scope.row.realName }}</span>
          <small>{{ scope.row.bossNumber }}</small>
        </template>
      </required-form-item-select>
      <el-form-item label="实控人">
        <el-input v-model="storeForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="实控人身份证">
        <el-input v-model="storeForm.idCard"></el-input>
      </el-form-item>

      <required-form-item-select
        class="formItem"
        ref="businessRef"
        v-model="storeForm.businessLicenseId"
        label="售达方编号"
        labelKey="licenseNumber"
        valueKey="id"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :total-count="businessCountTotal"
        :getDataList="getBusinessLicense"
        :getNextList="getBusinessLicense"
        @changeSelectedItem="
          e => {
            businessLicenseName = e.businessLicenseName
          }
        "
        @onOptionsListNotFound="onNotFoundBusinessLicenseId">
        <template v-slot="{ scope }">
          <span>{{ scope.row.licenseNumber }}</span>
          <small>{{ scope.row.businessLicenseName }}</small>
        </template>
      </required-form-item-select>

      <el-form-item label="售达方名称">
        <el-input v-model="storeForm.businessLicenseName"></el-input>
      </el-form-item>
      <el-form-item label="物理店编号">
        <el-input
          v-model="storeForm.parentShopNumber"
          type="number"
          @blur="parentShopNumberBlur"></el-input>
      </el-form-item>
      <required-form-item-select
        class="formItem"
        v-model="storeForm.authorizedCityCode"
        label="授权城市"
        :labelKey="item => `${item.city}${item.area || ''}${item.town || ''}`"
        valueKey="authorizedCityCode"
        :selectProps="{ bei: true, remote: true, filterable: true }"
        :total-count="authorizedCityCodeTotal"
        :getDataList="getAuthorizedCityList"
        :getNextList="getAuthorizedCityList"
        @changeSelectedItem="e => getAuthorizedCityName(e)"
        @onOptionsListNotFound="onNotFoundCityList">
        <template v-slot="{ scope }">
          <span>{{
            scope.row.city + (scope.row.area || '') + (scope.row.town || '')
          }}</span>
        </template>
      </required-form-item-select>
      <el-form-item label="门店名称">
        <el-input v-model="storeForm.shopName"></el-input>
      </el-form-item>
      <el-form-item label="门店简称">
        <el-input v-model="storeForm.shopAlias"></el-input>
      </el-form-item>
      <el-form-item label="省份">
        <el-input v-model="storeForm.province"></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input v-model="storeForm.city"></el-input>
      </el-form-item>
      <el-form-item label="地区">
        <el-input v-model="storeForm.area"></el-input>
      </el-form-item>
      <el-form-item label="街道">
        <el-input v-model="storeForm.street"></el-input>
      </el-form-item>
      <el-form-item label="门店位置等级">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.shopPositionLevel"
          label="门店位置等级"
          type="shop_level"
          :showLabel="false" />
      </el-form-item>
      <el-form-item label="门店楼层">
        <el-input v-model="storeForm.shopFloor"></el-input>
      </el-form-item>
      <el-form-item label="门店铺号">
        <el-input v-model="storeForm.shopFloorNumber"></el-input>
      </el-form-item>
      <el-form-item label="门店经度">
        <el-input v-model="storeForm.longitude"></el-input>
      </el-form-item>
      <el-form-item label="门店纬度">
        <el-input v-model="storeForm.latitude"></el-input>
      </el-form-item>
      <el-form-item label="渠道性质">
        <!-- <el-input v-model="storeForm.shopType"></el-input> -->
        <required-module-form-item-select
          label="渠道性质"
          type="store_type"
          :showLabel="false"
          v-model="storeForm.shopType"
          @changeSelectedItem="
            e => {
              shopTypeSelectedItem = e
              storeForm.shopTypeName = e.content
            }
          "
          @onChangeItem="changeShopType" />
      </el-form-item>
      <el-form-item label="渠道性质二级分类（商场名称）">
        <required-module-form-item-select
          v-if="
            ['SJ007', 'SJ001', 'SJ009', 'SJ010', 'SJ008'].includes(
              storeForm.shopType
            )
          "
          class="formItem"
          v-model="storeForm.shopTypeValue"
          :showLabel="false"
          label="渠道性质二级分类（商场名称）"
          type="secondary_store_type"
          valueKey="content"
          :getDataListParams="{
            parentType: 'store_type'
          }"
          :parent-code="storeForm.shopType"
          @onChangeItem="
            e => {
              if (!e) {
                storeForm.marketName = ''
                storeForm.marketType = ''
              }
            }
          " />
        <RequiredFormItemInput
          v-else-if="storeForm.shopType === 'SJ005'"
          v-model="storeForm.shopTypeValue"
          class="formItem"
          label="渠道性质二级分类（商场名称）"
          :showLabel="false"
          :disabled="!storeForm.shopType" />
        <required-form-item-input
          v-else
          class="formItem"
          label="渠道性质二级分类（商场名称）"
          :showLabel="false"
          v-model="storeForm.shopTypeValue"
          :disabled="true" />
      </el-form-item>
      <el-form-item label="卖场名称">
        <!-- <required-module-form-item-select
          class="formItem"
          v-model="storeForm.shopTypeThree"
          :showLabel="false"
          :required="false"
          type="three_store_type"
          value-key="content"
          :parent-content="storeForm.shopTypeValue"
          empty-disabled
          dependent-parent-content
          label="渠道性质三级分类"
        /> -->
        <!-- <RequiredFormItemInput
          v-if="!['SJ001', 'SJ008'].includes(storeForm.shopType)"
          v-model="storeForm.marketName"
          class="formItem"
          label="卖场名称"
          :showLabel="false"
          :disabled="true" /> -->
        <RequiredFormItemSelect
          v-if="!['SJ001', 'SJ008'].includes(storeForm.shopType)"
          v-model="storeForm.marketName"
          class="formItem"
          label="卖场名称"
          :dataList="marketNameSelectedItem"
          value-key="content"
          label-key="content"
          :showLabel="false"
          :required="false"
          :clearable="true"
          @onChangeItem="
            e => {
              storeForm.marketType = e ? e.type : ''
            }
          " />
        <RequiredModuleFormItemSelect
          v-else
          v-model="storeForm.marketName"
          class="formItem"
          :getDataListParams="{
            parentType: 'secondary_store_type',
            explainJson: storeForm?.authorizedCityName
          }"
          label="卖场名称"
          value-key="content"
          type="dealer_shop.market_name"
          :parent-content="storeForm.shopTypeValue"
          :showLabel="false"
          :required="false"
          @onChangeItem="handleChangeMarketName" />
      </el-form-item>
      <el-form-item label="卖场类型">
        <el-input v-model="storeForm.marketType" readonly></el-input>
      </el-form-item>
      <el-form-item label="店态性质">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.storeNature"
          :showLabel="false"
          label="店态性质"
          type="shop_nature_code"
          :getDataListParams="isV6 ? { moduleBrand: 'F' } : {}" />
      </el-form-item>
      <el-form-item label="门店租金">
        <el-input type="number" v-model="storeForm.shopRent"></el-input>
      </el-form-item>
      <el-form-item label="门店星级">
        <el-input v-model="storeForm.shopStarLevel"></el-input>
      </el-form-item>
      <el-form-item label="门店面积">
        <el-input type="number" v-model="storeForm.shopArea"></el-input>
      </el-form-item>
      <el-form-item v-if="storeForm.shopType === 'SJ003'" label="门头实用面积">
        <el-input type="number" v-model="storeForm.doorHeaderArea"></el-input>
      </el-form-item>
      <el-form-item label="开业时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.openingTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最新装修时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.decorationTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item
        v-if="storeForm.decorationStopType === '2'"
        label="装修到期时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.decorationStopTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="装修到期时间规则类型">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.decorationStopType"
          :showLabel="false"
          type="dealer_shop.decoration_stop_type"
          label="装修到期时间规则类型" />
      </el-form-item>
      <el-form-item label="经营模式">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.managementModel"
          :showLabel="false"
          label="经营模式"
          type="management_model" />
      </el-form-item>
      <el-form-item label="联系人" v-if="storeForm.managementModel !== '10'">
        <el-input v-model="storeForm.contactPerson"></el-input>
      </el-form-item>
      <el-form-item
        label="联系人身份证"
        v-if="storeForm.managementModel !== '10'">
        <el-input v-model="storeForm.contactPersonIdcard"></el-input>
      </el-form-item>
      <el-form-item
        label="联系人电话"
        v-if="storeForm.managementModel !== '10'">
        <el-input
          v-model="storeForm.contactPersonPhone"
          type="number"></el-input>
      </el-form-item>
      <!-- <el-form-item label="门店状态">
        <el-select v-model="storeForm.shopStatus"
                   placeholder="请选择门店状态">
          <el-option label="生效"
                     :value="0">
          </el-option>
          <el-option label="失效"
                     :value="1">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="门店状态说明">
        <!-- <el-input v-model="storeForm.privince"></el-input> -->

        <el-select
          v-model="storeForm.statusExplain"
          placeholder="请选择状态说明"
          @change="
            data => {
              if (data > 40) {
                storeForm.shopStatus = 1
              } else {
                storeForm.shopStatus = 0
              }
            }
          ">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in statusExplainOptions"
            :key="index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实际撤店时间">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="storeForm.actualWithdrawTime"
          type="date"
          placeholder="选择撤店时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否有儿童配套专区">
        <required-module-form-item-select
          class="formItem"
          v-model="ifChildrenSupportingArea"
          :showLabel="false"
          label="是否有儿童配套专区"
          type="flow_common_select_ifEnable"
          @changeSelectedItem="
            e => {
              handleChildren(e)
            }
          " />
      </el-form-item>
      <el-form-item
        label="儿童配套专区面积"
        v-show="ifChildrenSupportingArea === '1'">
        <el-input v-model="storeForm.childrenSupportingArea"></el-input>
      </el-form-item>
      <el-form-item label="渠道类型">
        <required-module-form-item-select
          class="formItem"
          v-model="storeForm.channel"
          :showLabel="false"
          label="渠道类型"
          type="cooperation_code" />
      </el-form-item>
      <div>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<script>
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import {
  getShopUpdate,
  getBossDataList,
  crmdistributorListPage,
  shopInfoDetail,
  authorizedcityListpage
} from '@/api/businessOos'

import * as BusinessApi from '@/api/attractPlatform/business'
import { getStorage } from '@/utils/storage'
import { getShopList } from '@/api/common'

export default {
  props: ['storeVisibleDialog', 'cancel', 'storeSelectItem'],
  components: {
    RequiredModuleFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      marketNameSelectedItem: [],
      storeForm: {},
      shopTypeSelectedItem: null,
      position: {},
      statusExplainOptions: [
        { label: '待设计', value: '9' },
        { label: '设计中', value: '10' },
        { label: '待装修', value: '15' },
        { label: '装修中', value: '20' },
        { label: '装修完成', value: '30' },
        { label: '正常营业', value: '40' },
        { label: '整改中', value: '45' },
        { label: '装修失效', value: '50' },
        { label: '取消经营', value: '51' },
        { label: '撤店失效', value: '60' },
        { label: '人工失效', value: '61' },
        { label: '系列全失效引起门店失效', value: '62' },
        { label: '转让失效', value: '70' }
      ],
      businessCountTotal: 0, // 售达方总数
      bossIdCountTotal: 0, // 实控人总数
      authorizedCityCodeTotal: 0, // 授权城市总数
      businessLicenseName: '', // 售达方名称
      idCard: 0, // 实控人身份证
      realName: '', // 实控人姓名
      selectionId: '',
      ifChildrenSupportingArea: '0', //是否儿童配套
      isV6: false // 是否V6
    }
  },
  watch: {
    storeSelectItem(val) {
      this.selectionId = val.id
      this.getBrandInfo()
      this.getShopInfoDetail()
    }
  },
  methods: {
    async getBrandInfo() {
      const res = await getShopList({
        shopId: this.selectionId
      })
      const list = res.data[0]?.shopBrandDetailsList
      this.isV6 = list.some(item =>
        item.bigBrandId.includes('1390119720541401089')
      )
    },
    storeNatureFilter(list) {
      if (this.isV6) {
        const V6filterList = ['寝具店', '客餐卧综合店', '大家居店']
        return list.filter(item => V6filterList.includes(item.content))
      }
      return list
    },
    // 选择卖场名称
    handleChangeMarketName(e) {
      if (!e) {
        this.storeForm.marketType = ''
        return
      }
      this.storeForm.marketType = e?.typeDetail ?? ''
      this.storeForm.shopPositionLevel = e
        ? this.storeForm.shopPositionLevel
        : ''
    },
    parentShopNumberBlur(e) {
      const value = e.target.value
      if (value.length > 7) {
        this.storeForm.parentShopNumber = value.slice(0, 7)
        this.$message.warning('请输入7位数字物理店编号')
      } else {
        this.storeForm.parentShopNumber = value.trim()
      }
    },
    changeSelectedItem(e) {
      console.log(e)
    },
    async getShopInfoDetail() {
      const res = await shopInfoDetail({ id: this.selectionId })
      this.$nextTick(() => {
        this.storeForm = res.data
        this.ifChildrenSupportingArea =
          this.storeForm.childrenSupportingArea > 0 ? '1' : '0'
        const timeId = setTimeout(() => {
          if (!['SJ001', 'SJ008'].includes(this.storeForm.shopType)) {
            this.storeForm.marketName = `${
              this.storeForm?.authorizedCityName || ''
            }${this.shopTypeSelectedItem?.content || ''}`
            this.storeForm.marketType = '非集中卖场'
            this.marketNameSelectedItem = [
              {
                content: this.storeForm.marketName,
                type: '非集中卖场'
              }
            ]
          }
          clearTimeout(timeId)
        }, 500)
      })
    },
    handleChildren(e) {
      if (e.code === '0' && this.storeForm.childrenSupportingArea > 0) {
        this.storeForm.childrenSupportingArea = 0
      }
    },
    // 授权城市列表
    async getAuthorizedCityList({ page, searchText }) {
      return authorizedcityListpage({
        currPage: page,
        addr: searchText
      }).then(res => {
        this.authorizedCityCodeTotal = res.totalCount
        return res.data
      })
    },
    // 默认第一页没有找到授权城市时调用
    async onNotFoundCityList({ value: authorizedCityCode }, callback) {
      authorizedCityCode &&
        callback(
          await authorizedcityListpage({
            authorizedCityCode
          }).then(res => res.data)
        )
    },
    // 获取授权城市名称
    getAuthorizedCityName(e) {
      const city = e.city
      const area = e.area || ''
      const town = e.town || ''
      this.storeForm.authorizedCityName = city + area + town
    },
    // 获取实控人列表
    async getbossIdList({ page, searchText }) {
      return getBossDataList({ currPage: page, realName: searchText }).then(
        res => {
          this.bossIdCountTotal = res.totalCount
          return res.data
        }
      )
    },
    // 默认第一页没有找到实控人
    async onNotFoundBossList({ value: id }, callback) {
      callback(
        await getBossDataList({
          id
        }).then(res => res.data)
      )
    },
    // 获取售达方列表
    async getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    async onNotFoundBusinessLicenseId({ value: id }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId: id
        }).then(res => res.data)
      )
    },
    onSubmit() {
      const userInfo = getStorage('userInfo')
      if (
        this.storeForm.shopType !== 'SJ003' &&
        this.storeForm.doorHeaderArea
      ) {
        this.storeForm.doorHeaderArea = ''
      }
      getShopUpdate({
        ...this.storeForm,
        updateUserName: userInfo?.realName
      }).then(res => {
        this.$message.success('保存成功')
        this.cancel()
        this.$emit('onUpdateStore')
      })
    },
    changeShopType(e) {
      this.storeForm.shopTypeValue = ''
      this.storeForm.shopPositionLevel = ''
      if (['SJ001', 'SJ008'].includes(e?.code)) {
        this.storeForm.marketName = ''
        this.storeForm.marketType = ''
      }
      this.$nextTick(() => {
        if (!e) return
        if (!['SJ001', 'SJ008'].includes(e?.code)) {
          this.storeForm.marketName = `${
            this.storeForm?.authorizedCityName || ''
          }${this.shopTypeSelectedItem?.content || ''}`
          this.storeForm.marketType = '非集中卖场'
          this.marketNameSelectedItem = [
            {
              content: this.storeForm.marketName,
              type: '非集中卖场'
            }
          ]
        }
        this.storeForm.shopTypeValue = [
          'SJ008',
          'SJ001',
          'SJ009',
          'SJ010',
          'SJ005'
        ].includes(e?.code)
          ? ''
          : this.shopTypeSelectedItem?.content
      })
    }
  }
}
</script>

<style lang="scss">
.form {
  .el-form-item {
    width: 250px;
  }
  .el-form-item__content,
  .el-form-item__label {
    text-align: left;
    display: block;
    width: 100%;
    .el-date-editor.el-input {
      width: 100%;
    }
  }
  .el-select {
    width: 100%;
  }
}
</style>
