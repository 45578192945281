<template>
  <div>
    <div ref="form">
      <el-form
        :inline="true"
        label-width="120px"
        :model="formInline"
        class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <template v-if="item.select">
            <request-module-select
              v-model="formInline[item.key]"
              :filterable="true"
              :placeholder="item.label"
              :type="item.model" />
          </template>
          <template v-else>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"></el-input>
          </template>
        </el-form-item>
        <required-brand-form-item-select
          v-model="formInline.brandId"
          :required="false"
          label="授权品牌："
          :select-props="{
            filterable: true,
            remote: true,
            clearable: true,
            multiple: false
          }"
          @changeSelect="value => {}" />
        <el-form-item>
          <el-button style="margin-right: 10px" type="primary" @click="query"
            >查询</el-button
          >
          <BrandExport
            title="导出门店子系列"
            v-permission="['shop_excel']"
            :loading="fullscreenLoading"
            @downloadExcel="uploadExcel" />
          <BrandExport
            title="导出门店总系列"
            v-permission="['shop_excel']"
            :loading="fullscreenLoading"
            @downloadExcel="uploadShopExcel" />
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px - 20px) ` }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%; margin-top: 20px"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        v-loading="loading"
        height="100%">
        <el-table-column
          prop="parentShopNumber"
          label="物理店编号"
          width="140" />
        <el-table-column prop="shopNumber" label="门店编号" width="140" />
        <el-table-column
          prop="shopName"
          label="门店名称"
          width="250"
          :showOverflowTooltip="true" />
        <el-table-column prop="statusExplain" label="门店状态" width="120">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <!-- <el-select :value="`${scope.row.storeNature}`" disabled filterable remote>
              <el-option v-for="item in storeNatureOption" :key="item.code" :label="item.content" :value="item.code">
              </el-option>
            </el-select> -->
              <request-module-select
                :value="scope.row.statusExplain"
                type="shop_status"
                :disabled="true"
                placeholder=" " />
              <!-- <div>
              <div v-for="item in typeList" :key="item.type">
                <span v-if="item.type == scope.row.statusExplain">{{item.value}}</span>
              </div>
            </div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopStatus" label="生效状态" width="120">
          <template slot-scope="scope">
            <div>
              {{ scope.row.shopStatus === 0 ? '生效' : '失效' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="storeNature" label="门店性质 " width="180">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <!-- <el-select :value="`${scope.row.storeNature}`" disabled filterable remote>
              <el-option v-for="item in storeNatureOption" :key="item.code" :label="item.content" :value="item.code">
              </el-option>
            </el-select> -->
              <request-module-select
                :value="scope.row.storeNature"
                type="shop_nature_code"
                :disabled="true"
                placeholder=" " />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="province" label="省份" width="140" />
        <el-table-column prop="city" label="地级市" width="140" />
        <el-table-column prop="area" label="区/县" width="140" />
        <el-table-column prop="contactPerson" label="门店联系人" width="130" />
        <el-table-column
          prop="contactPersonIdcard"
          label="联系人身份证"
          width="140" />
        <el-table-column
          prop="contactPersonPhone"
          label="联系人电话"
          width="120" />
        <el-table-column label="授权品牌" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('授权品牌', 'shopBrand', scope.row)">
                查看授权品牌
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dealerName" label="所属经销商" width="150" />
        <!-- <el-table-column label="是否展示店" width="150">
        <template slot-scope="scope">
          <div>
            {{scope.row.displayShopType?'是':'否'}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="displayShopType" label="展示店类型" width="150">
        <template slot-scope="scope">
          <div class="shopTypeSelect">
            <request-module-select v-if="scope.row.displayShopType" :value="scope.row.displayShopType"
              type="exhibition_shop_type" :disabled="true" />
          </div>
        </template>
      </el-table-column> -->
        <el-table-column prop="shopTypeName" label="渠道性质" width="150">
          <!-- <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.shopType"
                type="store_type"
                :disabled="true"
                placeholder=" "
              />
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="shopTypeValue"
          label="渠道性质二级分类"
          width="150">
          <!-- <template slot-scope="scope">
            <div>
              {{
                storeTypeList.find(
                  item => item.code === scope.row.shopTypeValue
                )
                  ? storeTypeList.find(
                      item => item.code === scope.row.shopTypeValue
                    ).content
                  : scope.row.shopTypeValue
              }}
            </div>
          </template> -->
        </el-table-column>
        <!-- <el-table-column
          prop="shopTypeThree"
          label="渠道性质三级分类"
          width="150"
        >
        </el-table-column> -->
        <el-table-column prop="marketName" label="卖场名称" width="150">
        </el-table-column>
        <el-table-column prop="marketType" label="卖场类型" width="150">
        </el-table-column>
        <el-table-column
          prop="shopPositionLevel"
          label="门店位置等级"
          width="150">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <!-- <CommonSelect class="selecItem" type="shop_level" v-model="scope.row.shopPositionLevel" label="门店位置等级"
              :showLabel="false" :prop="`shopList.${scope.$index}.shopPositionLevel`" /> -->
              <request-module-select
                :value="scope.row.shopPositionLevel"
                type="shop_level"
                :disabled="true"
                placeholder=" " />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="shopAreaLevel"
          label="门店面积级别"
          width="150" />
        <el-table-column
          prop="decorationStopTime"
          label="装修到期时间"
          width="150" />
        <!-- <el-table-column prop="shopStarLevel" label="门店星级" width="150" /> -->
        <el-table-column
          prop="actualWithdrawTime"
          label="实际撤店时间"
          width="150" />
        <el-table-column label="关联售达方" width="120">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联售达方', 'authorzed', scope.row)">
                查看售达方
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联送达方" width="120">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('关联送达方', 'boss', scope.row)">
                查看送达方
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110" fixed="right">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleConfirmDel(scope.row)">
              <el-link type="primary" slot="reference" :underline="false"
                >删除流程控制</el-link
              >
            </el-popconfirm>
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="showDialog('门店详情', 'shopDetail', scope.row)"
                    >门店详情</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="handleShowStoreDrawer(scope.row)"
                    v-permission="['edit_store_data']"
                    >门店数据变更</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    v-permission="['edit_shop_brand']"
                    :underline="false"
                    @click="
                      showDialog(
                        '新增/修改门店系列',
                        'editShopBrand',
                        scope.row
                      )
                    "
                    >修改门店系列</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    :underline="false"
                    v-permission="['edit_shop_sap']"
                    @click="handleExport(scope.row.id)"
                    >同步到SAP</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="showDialog('相关流程', 'dealer_shop', scope.row)"
                    >相关流程</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    :underline="false"
                    v-permission="['edit_shop_brand_auth']"
                    @click="
                      showDialog('品牌授权书', 'brandAuthorization', scope.row)
                    "
                    >品牌授权书</el-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-link
                    type="primary"
                    @click="handleShowRecordDrawer(scope.row)"
                    >变更记录</el-link
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <DialogTable
      :businessLicenseId="businessLicenseId"
      :visible.sync="dialogVisible"
      :title="title"
      :dataList="tableList"
      :type="type"
      :onChange="handleChange"
      :id="id"
      :rows="selectList"
      :authorizedCity="authorizedCityName"
      @onSave="handleSave"
      @onDelete="handleDel" />
    <storeDrawer
      :storeVisibleDialog.sync="storeVisibleDialog"
      :storeSelectItem="storeSelectItem"
      @onUpdateStore="onUpdateStore"
      :cancel="() => (storeVisibleDialog = false)" />

    <DetailStoreDrawer
      ref="detailStoreDrawer"
      :storeVisibleDialog.sync="detailStoreDrawerVisibleDialog"
      :selectItem="storeSelectItem"
      :updateDetailData="updateDetailData"
      :isOnlyShow="true"
      :showFooter="false"
      @onUpdateStore="getDataList"
      :cancel="() => (detailStoreDrawerVisibleDialog = false)" />
    <shopRecordDrawer
      :selectItem="selectRecordItem"
      @recordDetail="handleRecordDetail"
      ref="recordDrawer" />
  </div>
</template>

<script>
import DialogTable from './components/dialogTable'
import BrandExport from './components/brandExport'

import storeDrawer from './components/storeDrawer'

import DetailStoreDrawer from '../gmDataManege/components/storeDrawer'
import shopRecordDrawer from '../gmDataManege/components/shopRecordDrawer'

// import {
//   datadictionaryList
// } from '@/api/common'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import {
  getShopDataList,
  getBrandDataList,
  getShopBrandList,
  getMasterDataList,
  getBusinessDataList,
  getShopBrandDataList,
  saveShopBrand,
  delShopBrand,
  initializeSapDataByDataId,
  excelShop,
  excelShopFile,
  excelShopAllJson,
  getBossCorrelationInfo,
  deleteByNumber,
  requestGetAuthorizedBrand
} from '@/api/businessOos'
import { datadictionaryList } from '@/api/common'
import { getStorage } from '@/utils/storage'
import { tableHeight } from '@/mixin/tableHeight'
import { requestWrap } from '@/utils/requestWrap'
import { excelJsonToExcel } from './fn'
import { jsonValue, shopJsonValue } from './json'
const formInlineData = [
  {
    label: '门店编号',
    key: 'shopNumber',
    select: false
  },
  {
    label: '门店名称',
    key: 'shopName',
    select: false
  },
  {
    label: '门店性质 ',
    key: 'storeNature',
    select: true,
    model: 'shop_nature_code'
  },
  {
    label: '门店状态',
    key: 'statusExplain',
    select: true,
    model: 'shop_status'
  },
  {
    label: '生效状态',
    key: 'shopStatus',
    select: true,
    model: 'ifEnabled'
  },
  {
    label: '门店联系人',
    key: 'contactPerson',
    select: false
  },
  {
    label: '实控人',
    key: 'bossName',
    select: false
  },
  {
    label: '经销商编号',
    key: 'dealerCode',
    select: false
  },
  {
    label: '送达方编号',
    key: 'shopBrandNumber',
    select: false
  },
  {
    label: '省份',
    key: 'province',
    select: false
  },
  {
    label: '市区',
    key: 'city',
    select: false
  },
  {
    label: '区/镇/县',
    key: 'area',
    select: false
  },

  {
    label: '城市等级',
    key: 'marketLevelName',
    select: true,
    model: 'city_level_name'
  }
]

export default {
  components: {
    DialogTable,
    RequestModuleSelect,
    storeDrawer,
    RequiredBrandFormItemSelect,
    BrandExport,
    DetailStoreDrawer,
    shopRecordDrawer
  },
  mixins: [tableHeight],
  data() {
    return {
      selectList: {},
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      shopTypeOption: [],
      storeNatureOption: [],
      id: '',
      status: 0,
      loading: false,
      storeVisibleDialog: false, // 门店数据变更
      storeSelectItem: {},
      businessLicenseId: '',
      timer: 0,
      timer_others: 0,
      userInfo: {},
      storeTypeList: [], // 门店二级分类列表
      fullscreenLoading: false,
      authorizedCityName: '',
      detailStoreDrawerVisibleDialog: false,
      updateDetailData: {},
      selectRecordItem: {},
      storeSelectItem: {}
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getTypeList()
    // this.getShopType()
  },
  methods: {
    handleShowRecordDrawer(item) {
      this.selectRecordItem = item
      this.$refs.recordDrawer.showRecordDrawer = true
    },
    handleRecordDetail(item) {
      this.$refs.detailStoreDrawer.storeVisibleDialog = true
      this.storeSelectItem = JSON.parse(item.historicalData)
      this.storeSelectItem.updateRemark = item.remark
      this.updateDetailData = JSON.parse(item.afterInfo)
    },
    // 获取类型
    async getTypeList() {
      const res = await datadictionaryList({
        currPage: 1,
        pageSize: 1000,
        type: 'secondary_store_type'
      })
      this.storeTypeList.push(...res.data)
      this.getBrandList()
      this.getDataList()
    },
    onUpdateStore() {
      this.getDataList()
    },
    async handleExport(id) {
      await initializeSapDataByDataId({ dataId: id, initializeDataType: 10 })
      this.$message.success('同步成功！')
    },
    handleShowStoreDrawer(item) {
      this.storeVisibleDialog = true
      this.storeSelectItem = item
    },

    // 获取门店类型选项
    // async getShopType() {
    //   const res = await datadictionaryList({
    //     type: 'store_type',
    //     module: 'distributor',
    //     currPage: 1,
    //     pageSize: 1000
    //   })
    //   console.log(res);
    //   this.shopTypeOption = res.data
    // },
    // // 获取门店性质
    // async getShopType() {
    //   this.storeNatureOption = await datadictionaryList({
    //     type: 'shop_nature_code',
    //     module: 'distributor',
    //     currPage: 1,
    //     pageSize: 1000
    //   }).then(res => res.data)
    // },
    // 获取门店列表
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })

      this.loading = true
      const res = await getShopDataList({
        ...this.formInline,
        accountId: this.userInfo.accountId,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        res.data.forEach(item => {
          if (item.decorationTime) {
            const arr = item.decorationTime.split('-')
            if (item.shopType == '1' || item.shopType == '4') {
              arr[0] = String(Number(arr[0]) + 4)
            } else {
              arr[0] = String(Number(arr[0]) + 6)
            }
            item.endDecorationTime = arr.join('-')
          }
        })
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 获取授权系列
    async getBrandList() {
      const res = await getBrandDataList()
      if (res.code === 0) {
        this.formInlineData[4].options = res.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      }
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.selectList = data
      this.id = data.id
      this.authorizedCityName = data.authorizedCityName
      switch (type) {
        case 'shopBrand':
          //查看授权品牌
          requestGetAuthorizedBrand({
            shopId: data.id,
            ifEnabled: -1
          }).then(res => {
            this.tableList = res.data
          })
          break
        case 'boss':
          //查看送达方
          this.getBossCorrelationInfo({
            shopId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'authorized_brand'
          })
          break
        case 'authorzed':
          //查看售达方
          this.getBossCorrelationInfo({
            shopId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'license'
          })
          break
        case 'shopDetail':
          this.getShopDetail(data.id)
          break
        case 'editShopBrand':
          this.businessLicenseId = data.businessLicenseId
          this.getEditShopBrand(data.id)
          break
        case 'brandAuthorization':
          this.getbrandAuthorization(data)
          break
      }
    },
    getBossCorrelationInfo(params) {
      getBossCorrelationInfo(params).then(res => {
        this.tableList = res.data
      })
    },
    // 导出门店数据
    // async uploadShopExcel() {
    //   if (this.timer_others === 1) return
    //   this.fullscreenLoading = true
    //   try {
    //     this.timer_others = 1
    //     const config = requestWrap({
    //       url: 'dealermanage/shop/getDealerShopSunExport'
    //     })
    //     await excelShopFile(config.params)
    //   } catch (error) {
    //     console.log(error)
    //     this.$confirm('导出excel失败', '提示', {
    //       confirmButtonText: '确定',
    //       type: 'error'
    //     })
    //   } finally {
    //     this.timer_others = 0
    //     this.fullscreenLoading = false
    //   }
    // },
    async uploadShopExcel(val) {
      if (this.timer === 1) return
      this.fullscreenLoading = true
      try {
        this.timer = 1
        const res = await excelShopAllJson({
          cityLevelBrand: val,
          accountId: this.userInfo.accountId
        })
        excelJsonToExcel(shopJsonValue, res.data, '门店总系列')
        // console.log(res.data)
      } catch (error) {
        console.log(error)
        this.$confirm('导出excel失败', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        })
      } finally {
        this.timer = 0
        this.fullscreenLoading = false
      }
    },
    // 导出excel
    async uploadExcel(val) {
      if (this.timer === 1) return
      this.fullscreenLoading = true
      try {
        this.timer = 1
        const res = await excelShop({
          cityLevelBrand: val,
          accountId: this.userInfo.accountId
        })
        excelJsonToExcel(jsonValue, res.data, '门店子系列')
        // console.log(res.data)
        // this.$message.success('正在导出中...')
        // this.$load.close()
        // const filterVal = jsonValue.map(item => item.prop)
        // const data = this.formatJson(filterVal, res.data)
        // import('@/vendor/Export2Excel').then(excel => {
        //   const tHeader = jsonValue.map(item => item.title)
        //   excel.export_json_to_excel({
        //     header: tHeader, //表头 必填
        //     data, //具体数据 必填
        //     filename: '门店子系列' //非必填
        //   })
        // })
      } catch (error) {
        console.log(error)
        this.$confirm('导出excel失败', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        })
      } finally {
        this.timer = 0
        this.fullscreenLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          switch (j) {
            case 'storeNature':
              const data = options.find(item => item.value == v[j])
              if (v[j] && data) {
                return data.label
              } else {
                return v[j]
              }
            case 'ifEnabled':
              const ifEnabledData = ifEnabledOptions.find(
                item => item.value == v[j]
              )
              if (v[j] && ifEnabledData) {
                return ifEnabledData.label
              } else {
                return v[j]
              }
            case 'partnership':
              const partnerData = partnerOptions.find(
                item => item.value == v[j]
              )
              if (v[j] && partnerData) {
                return partnerData.label
              } else {
                return v[j]
              }
            case 'managementModel':
              const managementModelData = managementModelOptions.find(
                item => item.value == v[j]
              )
              if (v[j] && managementModelData) {
                return managementModelData.label
              } else {
                return v[j]
              }
            case 'independentDoorHeadFlag':
              const independentDoorHeadFlagData = modalStatus.find(
                item => item.value == v[j]
              )
              if (v[j] && independentDoorHeadFlagData) {
                return independentDoorHeadFlagData.label
              } else {
                return v[j]
              }
            case 'shopType':
              const shopTypeData = shopTypeOption.find(
                item => item.value == v[j]
              )
              if (v[j] && shopTypeData) {
                return shopTypeData.label
              } else {
                return v[j]
              }
            case 'statusExplain':
              const shopStatusData = shopStatusOptions.find(
                item => item.value == v[j]
              )
              if (v[j] && shopStatusData) {
                return shopStatusData.label
              } else {
                return v[j]
              }
            default:
              return v[j]
          }
          // if (j === 'storeNature') {
          //   const data = options.find(item => item.value == v[j])
          //   if (v[j] && data) {
          //     return data.label
          //   } else {
          //     return v[j]
          //   }
          //   //  return options.find(item=>item.value == v[j]).label
          // } else {
          //   return v[j]
          // }
        })
      )
    },
    // 获取授权品牌
    async getBrandDataList(id) {
      const res = await getShopBrandList({
        shopId: id,
        ifEnabled: -1
      })
      this.tableList = res.data
    },
    // 获取售达方
    async getAuthorzedList(id) {
      const res = await getBusinessDataList({
        shopId: id,
        shopStatus: -1
      })
      this.tableList = res.data
    },
    // 获取送达方
    async getBossList(data, status = 0) {
      const res = await getMasterDataList({
        shopId: data.id,
        ifEnabled: -1
      })
      this.tableList = res.data
    },
    // 获取门店详情
    getShopDetail(id) {
      const data = this.dataList.find(item => item.id == id)
      // console.log(data);
      this.tableList = [data]
    },
    // 获取品牌授权书
    getbrandAuthorization(data) {},
    // 送达方切换
    handleChange(id, type) {
      this.status = type == 'new' ? 2 : 0
      const data = this.dataList.find(item => item.id == id)
      this.getBossList(data, this.status)
    },
    // 获取修改门店系列列表
    async getEditShopBrand(id) {
      const res = await getShopBrandDataList({ shopId: id, queryAll: 3 })
      this.tableList = res.data
    },
    // 保存门店系列
    async handleSave(form) {
      const res = await saveShopBrand(form)
      if (res.code === 0) {
        this.getEditShopBrand(this.id)
        this.$message({
          type: 'success',
          message: '保存成功!'
        })
      }
    },
    // 删除门店系列
    async handleDel(ids) {
      const res = await delShopBrand(ids)
      if (res.code === 0) {
        this.$message({
          type: 'success',
          message: '失效成功!'
        })
        this.getEditShopBrand(this.id)
      }
    },
    //删除流程控制
    handleConfirmDel(row) {
      deleteByNumber({ shopNumber: row.shopNumber }).then(res => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          // this.getProcessControlList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

:deep(.el-form) {
  .el-input__inner {
    width: 180px;
  }
}

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 12px;
  color: #409eff;
  &:hover {
    opacity: 0.8;
  }
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
