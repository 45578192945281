import { title } from "@/settings"

export const jsonValue = [
  {
    title: '营销部',
    prop: 'bigRegionName'
  },
  {
    title: '营销中心',
    prop: 'smallRegionName'
  },
  {
    title: '实控人编号',
    prop: 'bossNumber'
  },
  {
    title: '实控人名称',
    prop: 'realName'
  },
  {
    title: '经销商编号',
    prop: 'dealerCode'
  },
  {
    title: '经销商名称',
    prop: 'dealerName'
  },
  {
    title: '送达方编号汇总',
    prop: 'shopBrandNumberSum'
  },
  {
    title: '送达方名称汇总',
    prop: 'shopBrandNameSum'
  },
  {
    title: '售达方编号',
    prop: 'licenseNumber'
  },
  {
    title: '售达方名称',
    prop: 'businessLicenseName'
  },
  {
    title: '物理店号',
    prop: 'parentShopNumber'
  },
  {
    title: '门店编号',
    prop: 'shopNumber'
  },
  {
    title: '门店名称',
    prop: 'shopName'
  },
  {
    title: '授权城市名称',
    prop: 'authorizedCityName'
  },
  {
    title: '城市级别',
    prop: 'marketLevelName'
  },
  {
    title: '城市级别二级分类',
    prop: 'marketLevelTypeName'
  },
  {
    title: '省',
    prop: 'province'
  },
  {
    title: '市',
    prop: 'city'
  },
  {
    title: '区/县',
    prop: 'area'
  },
  {
    title: '街道',
    prop: 'street'
  },
  {
    title: '渠道性质',
    prop: 'shopType'
  },
  {
    title: '渠道性质二级分销',
    prop: 'shopTypeValue'
  },
  {
    title: '门店楼层',
    prop: 'shopFloor'
  },
  {
    title: '门店铺号',
    prop: 'shopFloorNumber'
  },
  {
    title: '门店地址',
    prop: 'shopAddress'
  },
  {
    title: '门店性质',
    prop: 'storeNature'
  },
  {
    title: '门店位置等级',
    prop: 'shopPositionLevel'
  },
  {
    title: '门店星级',
    prop: 'shopStarLevel'
  },
  {
    title: '开业时间',
    prop: 'openingTime'
  },
  {
    title: '装修时间',
    prop: 'decorationTime'
  },
  {
    title: '装修到期时间',
    prop: 'decorationStopTime'
  },
  {
    title: '装修到期时间规则类型',
    prop: 'decorationStopType'
  },
  {
    title: '装修风格',
    prop: 'renovationStyle'
  },
  {
    title: '经营模式',
    prop: 'managementModel'
  },
  {
    title: '门店状态',
    prop: 'statusExplain'
  },
  {
    title: '撤店时间',
    prop: 'actualWithdrawTime'
  },
  {
    title: '撤店原因',
    prop: 'actualWithdrawReason'
  },
  {
    title: '门店总面积',
    prop: 'shopArea'
  },
  {
    title: '门店子系列',
    prop: 'name'
  },
  {
    title: '门店子系列状态',
    prop: 'ifEnabled'
  },
  {
    title: '门店子系列面积',
    prop: 'brandArea'
  },
  {
    title: '系列送达方编码',
    prop: 'shopBrandNumber'
  },
  {
    title: '系列送达方名称',
    prop: 'shopBrandName'
  },
  {
    title: '门店租金（万元/年）',
    prop: 'shopRent'
  },
  {
    title: '样品套数',
    prop: 'sampleSets'
  },
  {
    title: '门店联系人',
    prop: 'contactPerson'
  },
  {
    title: '门店联系人电话',
    prop: 'contactPersonPhone'
  },
  {
    title: '订单专员账号',
    prop: 'orderSpecialistNumber'
  },
  {
    title: '订单专员名称',
    prop: 'orderSpecialist'
  },
  {
    title: '独立外立门头',
    prop: 'independentDoorHeadFlag'
  },
  {
    title: '授权时间',
    prop: 'authorizedTime'
  },
  {
    title: '取消授权时间',
    prop: 'overTime'
  }
]

// 门店总系列
export const shopJsonValue = [
  {
    title: '营销部',
    prop: 'bigRegionName'
  },
  {
    title: '营销中心',
    prop: 'smallRegionName'
  },
  {
    title: '送达方编号',
    prop: 'shopBrandNumberSum'
  },
  {
    title: '送达方名称',
    prop: 'shopBrandNameSum'
  },
  {
    title: '售达方编号',
    prop: 'licenseNumber'
  },
  {
    title: '售达方名称',
    prop: 'businessLicenseName'
  },
  {
    title: '实控人编号',
    prop: 'bossNumber'
  },
  {
    title: '实控人名称',
    prop: 'realName'
  },
  {
    title: '经销商编号',
    prop: 'dealerCode'
  },
  {
    title: '经销商名称',
    prop: 'dealerName'
  },
  {
    title: '省',
    prop: 'province'
  },
  {
    title: '市',
    prop: 'city'
  },
  {
    title: '县',
    prop: 'area'
  },
  {
    title: '授权城市名称',
    prop: 'authorizedCityName'
  },
  {
    title: '城市级别',
    prop: 'marketLevelName'
  },
  {
    title: '城市级别二级分类',
    prop: 'marketLevelTypeName'
  },
  {
    title: '物理店号',
    prop: 'parentShopNumber'
  },
  {
    title: '门店编号',
    prop: 'shopNumber'
  },
  {
    title: '门店名称',
    prop: 'shopName'
  },
  {
    title: '门店简称',
    prop: 'shopAlias'
  },
  {
    title: '门店地址',
    prop: 'shopAddress'
  },
  {
    title: '渠道性质',
    prop: 'shopType'
  },
  {
    title: '渠道性质二级分类',
    prop: 'shopTypeValue'
  },
  {
    title: '卖场名称',
    prop: 'marketName'
  },
  {
    title: '卖场类型',
    prop: 'marketType'
  },
  {
    title: '店态性质',
    prop: 'storeNature'
  },
  {
    title: '开业时间',
    prop: 'openingTime'
  },
  {
    title: '最新装修时间',
    prop: 'decorationTime'
  },
  {
    title: '装修截至时间',
    prop: 'decorationStopTime'
  },
  {
    title: '装修到期时间规则类型',
    prop: 'decorationStopType'
  },
  {
    title: '装修风格',
    prop: 'renovationStyle'
  },
  {
    title: '撤店时间',
    prop: 'actualWithdrawTime'
  },
  {
    title: '撤店原因',
    prop: 'actualWithdrawReason'
  },
  {
    title: '门店状态',
    prop: 'statusExplain'
  },
  {
    title: '门店面积',
    prop: 'shopArea'
  },
  {
    title: '门店系列面积总',
    prop: 'shopSumBrandArea'
  },
  {
    title: '门店系列总(有效)',
    prop: 'shopSumBrand'
  },
  {
    title: '门店系列总(失效)',
    prop: 'shopSumBrandOver'
  },
  {
    title: '独立外立门头',
    prop: 'independentDoorHeadFlag'
  },
  {
    title: '门店位置等级',
    prop: 'shopPositionLevel'
  },
  {
    title: '门店租金(万元/年)',
    prop: 'shopRent'
  },
  {
    title: '门店联系人',
    prop: 'contactPerson'
  },
  {
    title: '门店联系人电话',
    prop: 'shopTel'
  },
  {
    title: '经营模式',
    prop: 'managementModel'
  },
  {
    title: '门店星级',
    prop: 'shopStarLevel'
  },
  {
    title: '门店面积级别',
    prop: 'shopAreaLevel'
  },
  {
    title: '门店类型',
    prop: 'content'
  },
  {
    title: '街道',
    prop: 'street'
  },

  {
    title: '门店楼层',
    prop: 'shopFloor'
  },
  {
    title: '门店铺号',
    prop: 'shopFloorNumber'
  },
  {
    title: '是否海外授权系列标记',
    prop: 'overseasFlag'
  }
]

// 经销商列表
export const dealerJsonValue = [
  {
    title: '授权城市名称',
    prop: 'authorizedCityName'
  },
  {
    title: '省份',
    prop: 'province'
  },
  {
    title: '经销商编号',
    prop: 'dealerCode'
  },
  {
    title: '经销商名称',
    prop: 'dealerName'
  },
  {
    title: '实控人名称',
    prop: 'realName'
  },
  {
    title: '实控人编码',
    prop: 'bossNumber'
  },
  {
    title: '实控人联系方式',
    prop: 'contactInfo'
  },
  {
    title: '历史实控人',
    prop: 'historyBoss'
  },
  {
    title: '有效授权系列',
    prop: 'brands'
  },
  {
    title: '(失效)授权系列',
    prop: 'overBbrands'
  },
  {
    title: '市场级别名称',
    prop: 'marketLevelName'
  },
  {
    title: '有效/无效',
    prop: 'ifEnabled'
  },
  {
    title: '授权时间（毫秒）',
    prop: 'createTime'
  },
  {
    title: '经典-授权时间',
    prop: 'jdBrandTime'
  },
  {
    title: '营销部',
    prop: 'bigRegionName'
  },
  {
    title: '营销中心',
    prop: 'smallRegionName'
  },
  {
    title: '经销商级别/AB商',
    prop: 'dealerLevel'
  },
  {
    title: '首批摆场样品下单终审时间',
    prop: 'firstOpeningTime'
  },
  {
    title: '经销商4S星级',
    prop: 'starRating'
  },
  {
    title: '经典经销商状态',
    prop: 'jdDealerStatus'
  },
  {
    title: '空白/转让加盟',
    prop: 'newOrTransfer'
  }
]

// 售达方列表
export const authorizedJsonValue = [
  {
    title: '售达方编号',
    prop: 'licenseNumber'
  },
  {
    title: '社会统一信用代码',
    prop: 'creditCode'
  },
  {
    title: '营业执照名称',
    prop: 'businessLicenseName'
  },
  {
    title: '营业执照类型',
    prop: 'businessLicenseType'
  },
  {
    title: '实控人编号',
    prop: 'bossNumber'
  },
  {
    title: '实控人名称',
    prop: 'bossName'
  },
  {
    title: '注册资本（*万）',
    prop: 'registeredCapital'
  },
  {
    title: '法人姓名',
    prop: 'legalPersonName'
  },
  {
    title: '法人联系方式',
    prop: 'legalPersonContact'
  },
  {
    title: '注册日期',
    prop: 'registerDate'
  },
  {
    title: '营业期限',
    prop: 'businessTerm'
  },
  {
    title: '营业执照地址',
    prop: 'businessLicenseAddress'
  },
  {
    title: '经营范围',
    prop: 'businessScope'
  },
  {
    title: '登记机关',
    prop: 'registrationAuthority'
  },
  {
    title: '函证接收地址',
    prop: 'certificatesRecAddress'
  },
  {
    title: '接收电子发票邮箱',
    prop: 'eMail'
  },
  {
    title: '状态说明',
    prop: 'statusExplain'
  },
  {
    title: '开户行',
    prop: 'bank'
  },
  {
    title: '开户行账号',
    prop: 'bankAccount'
  },
  {
    title: '开户行账号名称',
    prop: 'bankAccountName'
  },
  {
    title: '开票名称',
    prop: 'businessLicenseNames'
  },
  {
    title: '纳税人电话',
    prop: 'taxpayerPhone'
  },
  {
    title: '纳税类型',
    prop: 'payTaxesType'
  },
  {
    title: '开票类型',
    prop: 'billType'
  },
  {
    title: '税务负责人姓名',
    prop: 'personLiableName'
  },
  {
    title: '税务负责人电话',
    prop: 'personLiablePhone'
  },
  {
    title: '创建时间',
    prop: 'createTime'
  },
  {
    title: '更新时间',
    prop: 'updateTime'
  }
]

// 送达方列表
export const masterJsonValue = [
  {
    title: '送达方编号',
    prop: 'shopBrandNumber'
  },
  {
    title: '送达方名称',
    prop: 'authorizedBrandName'
  },
  {
    title: '送达方状态',
    prop: 'statusExplain_authorized'
  },
  {
    title: '渠道合作类型',
    prop: 'channelNames'
  },
  {
    title: '品牌',
    prop: 'name'
  },
  {
    title: '授权系列',
    prop: 'seriesName'
  },
  {
    title: '售达方编号',
    prop: 'licenseNumber'
  },
  {
    title: '售达方名称',
    prop: 'businessLicenseName'
  },
  {
    title: '售达方状态',
    prop: 'ifEnabled'
  },
  {
    title: '实控人编号',
    prop: 'bossNumber'
  },
  {
    title: '实控人名称',
    prop: 'realName'
  },
  {
    title: '授权城市名称',
    prop: 'authorizedCityName'
  },
  {
    title: '经销商编号',
    prop: 'dealerCode'
  },
  {
    title: '经销商名称',
    prop: 'dealerName'
  },
  {
    title: '经销商状态',
    prop: 'dealerStatus'
  },
  {
    title: '城市级别名称',
    prop: 'marketLevelName'
  },
  {
    title: '城市级别二级分类名称',
    prop: 'marketLevelTypeName'
  },
  {
    title: '订单专员工号',
    prop: 'orderSpecialistNumber'
  },
  {
    title: '订单专员名称',
    prop: 'orderSpecialist'
  },
  {
    title: '省份',
    prop: 'province'
  },
  {
    title: '城市',
    prop: 'city'
  },
  {
    title: '区县',
    prop: 'area'
  },
  {
    title: '营销部',
    prop: 'bigRegionName'
  },
  {
    title: '营销中心',
    prop: 'smallRegionName'
  },
  {
    title: '是否关闭下单权限',
    prop: 'orderAuthority'
  }
]

// 实控人列表
export const bossJsonValue = [
  {
    title: '人员编码',
    prop: 'bossNumber'
  },
  {
    title: '姓名',
    prop: 'realName'
  },
  {
    title: '人员类型',
    prop: 'type'
  },
  {
    title: '角色',
    prop: 'role'
  },
  {
    title: '性别',
    prop: 'sex'
  },
  {
    title: '联系方式',
    prop: 'contactInfo'
  },
  {
    title: '电子邮箱',
    prop: 'eMail'
  },
  {
    title: '身份证',
    prop: 'idCard'
  },
  {
    title: '学历',
    prop: 'education'
  },
  {
    title: '年龄',
    prop: 'age'
  },
  {
    title: '婚姻状况',
    prop: 'maritalStatus'
  },
  {
    title: '籍贯',
    prop: 'nativePlace'
  },
  {
    title: '人员状态',
    prop: 'ifEnabled'
  },
  {
    title: '创建时间',
    prop: 'createTime'
  }
]

//门店转让详情
export const aStoreDetail = [
  {
    title: '门店编号',
    prop: 'shopNumber'
  },
  {
    title: '转让流程单号',
    prop: 'orderNumber'
  },
  {
    title: '历史售达方编号',
    prop: 'oldLicenseNumber'
  },
  {
    title: '历史售达方名称',
    prop: 'oldLicenseName'
  },
  {
    title: '历史实控人编号',
    prop: 'oldBossNumber'
  },
  {
    title: '历史实控人名称',
    prop: 'oldBossName'
  },
  {
    title: '现售达方编号',
    prop: 'newLicenseNumber'
  },
  {
    title: '现售达方名称',
    prop: 'newLicenseName'
  },
  {
    title: '现实控人编号',
    prop: 'newBossNumber'
  },
  {
    title: '现实控人名称',
    prop: 'newBossName'
  },
  {
    title: '转让时间',
    prop: 'overTime'
  },
  {
    title: '门店名称',
    prop: 'shopName'
  },
  {
    title: '省份',
    prop: 'province'
  },
  {
    title: '城市',
    prop: 'city'
  },
  {
    title: '地区',
    prop: 'area'
  },
  {
    title: '街道',
    prop: 'street'
  },
  {
    title: '门店位置等级',
    prop: 'shopPositionLevel'
  },
  {
    title: '门店楼层',
    prop: 'shopFloor'
  },
  {
    title: '门店铺号',
    prop: 'shopFloorNumber'
  },
  {
    title: '门店经度',
    prop: 'longitude'
  },
  {
    title: '门店纬度',
    prop: 'latitude'
  },
  {
    title: '门店租金（万元/年）',
    prop: 'shopRent'
  },
  {
    title: '开业时间',
    prop: 'openingTime'
  },
  {
    title: '最新装修时间',
    prop: 'decorationTime'
  },
  {
    title: '经营模式',
    prop: 'managementModel'
  },
  {
    title: '联系人',
    prop: 'contactPerson'
  },
  {
    title: '联系人身份证',
    prop: 'contactPersonIdcard'
  },
  {
    title: '联系人电话',
    prop: 'contactPersonPhone'
  },
  {
    title: '门店状态',
    prop: 'statusExplain'
  },
  {
    title: '实际撤店时间',
    prop: 'actualWithdrawTime'
  },
  {
    title: '门店性质',
    prop: 'storeNature'
  },
  {
    title: '渠道类型',
    prop: 'channel'
  },
  {
    title: '渠道性质',
    prop: 'shopType'
  },
  {
    title: '渠道性质二级分类',
    prop: 'shopTypeValue'
  },
  {
    title: '门店地址',
    prop: 'shopName'
  },
  {
    title: '独立外立面门头',
    prop: 'independentDoorHeadFlag'
  },
  {
    title: '二级分销商',
    prop: 'contactPerson'
  },
  {
    title: '二级分销商身份证',
    prop: 'contactPersonIdcard'
  },
  {
    title: '二级分销商联系方式',
    prop: 'contactPersonPhone'
  },
  {
    title: '儿童配套专区面积',
    prop: 'childrenSupportingArea'
  }
]
